import React, { useEffect } from "react";
import app from "./configuration";
import { doc, getFirestore } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';

// App.js

function App() {
  useEffect(() => {
  }, []);

  return (
    <div>
      <h1>Data from database (main):</h1>
      <FirestoreDocument />
    </div>
  );
}

const FirestoreDocument = () => {
  const [value, loading, error] = useDocument(
    doc(getFirestore(app), `your_collection/test`, ''),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  return (
    <div>
      <p>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Document: Loading...</span>}
        <ul>{value && value.data()?.test.map((item: string, index: number) => (
          <li key={index}>{item}</li>
        ))}</ul>
      </p>
    </div>
  );
};

export default App;