// Import the necessary Firebase modules
import { initializeApp } from "firebase/app";

// Your Firebase config here
const firebaseConfig = {
  apiKey: "AIzaSyByk1biDa8_NkSbBEeWBo7f2dRHNS9cugQ",
  authDomain: "photornado-v3.firebaseapp.com",
  projectId: "photornado-v3",
  storageBucket: "photornado-v3.appspot.com",
  messagingSenderId: "615667610327",
  appId: "1:615667610327:web:ad63e0e21366d61f18f692",
  measurementId: "G-P6ZRYZKX8L",
  databaseURL: 'https://photornado-v3-default-rtdb.europe-west1.firebasedatabase.app'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;


